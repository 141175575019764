@import url("/src/colors.css");

.manage {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    text-align: center;
    border-bottom: 0.5px solid var(--main-grey-2);
    position: fixed;
    top: 0;
    background-color: #fff;
    width: 100vw;
    z-index: 101;
    height: 50px;
    min-width: 766px;
}

html {
    width: 100%;
    height: 100%;
}

body {
    margin: 0;
    height: 100%;
    width: 100%;
    padding: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


#logo {
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    width: 163px;
    height: 49px;
    cursor: pointer;
}

a {
    cursor: pointer;
}

#my_boards {
    cursor: pointer;
    text-decoration: none;
    margin-top: 4px;
    text-align: center;
    white-space: nowrap;
    height: 30px;
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    text-align: left;
    padding-left: 25px;
    color: rgb(161, 157, 157);
    border-left: 0.5px solid var(--main-grey-2);
}

#my_boards:hover {
    color:rgb(7, 7, 7);
    opacity: 70%;
}

.error {
    position: fixed; 
    z-index: 300; 
    top: 0; 
    width: 90%;
    margin: 5%;
    opacity: 0;
    visibility: hidden;
    transition: 300ms;
}

.error.active {
    opacity: 1;
    visibility: visible;
}

.error-popup {
    background-color: white;
    top: 10%;
    width: 90%;
    opacity: 0;
    visibility: hidden;
    border-radius: 5px;
    border: 2px solid rgba(255, 0, 0, 0.5);
    color: rgba(151, 38, 38, 0.9);
    position: absolute;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: 300ms;
}

.error-popup p {
    font-size: 16px;
    font-weight: 500;
}

.error-popup.active {
    opacity: 1;
    visibility: visible;
}

.user-profile {
    height: 100%;
    max-width: 460px;
    flex-wrap: nowrap;
    margin: 0 12px;
    text-align: center;
    line-height: 26px;
    white-space: nowrap;
    border-radius: 0.3em;
    text-decoration: none;
    margin-left: auto;
    display: flex;
    right: 0;
    justify-content: right;
    align-items: center;
    color: #A19D9D;
}

.profile-dropdown {
    width: 160px;
    border-radius: 5px;
    border: 1px solid var(--gray-5, #E0E0E0);
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
    opacity: 0;
    font-size: 16px;
    font-weight: 500;
    visibility: hidden;
    z-index: 1000;
    top: 35px;
    right: 10px;
    transition: 300ms;
}

.profile-dropdown p {
    z-index: 100;
    cursor: pointer;
    color: #585757;
    opacity: 70%;
    margin: 0 0 0 0;
}

.profile-dropdown p:hover {
    opacity: 100%;
}

.profile-dropdown div {
    display: flex;
    margin: 8px 0;
    align-items: center;
}


.profile-dropdown div img {
    width: 18px;
    margin: 0 7px 0 10px;
}

.user-profile .more-vertical:hover  ~ .profile-dropdown{
    visibility: visible;
    opacity: 1;
}

.profile-dropdown:hover {
    visibility: visible;
    opacity: 1;
}

.user-profile .icon {
    height: 30px;
    width: 30px;
    background-color: white;
    border-radius: 50px;
    border: 1px solid var(--main-grey);
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-sign-up {
    height: 26px;
    width: 109px;
    text-align: center;
    line-height: 26px;
    white-space: nowrap;
    color: white;
    background-color: #000;
    border: 1px solid #000;
    border-radius: 0.3em;
    text-decoration: none;
    margin-left: auto;
    position: fixed;
    right: 146px;
}

.btn-sign-in, .btn-sign-up {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; 
}

.btn-sign-in {
    height: 26px;
    width: 109px;
    text-align: center;
    line-height: 26px;
    white-space: nowrap;
    margin: 0 20px 0 16px;
    background-color: white;
    border: 1px solid #000;
    border-radius: 0.3em;
    color: #000;
    text-decoration: none;
    position: fixed;
    right: 0;
}

.delete-profile-popup {
    padding: 60px 81px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #FFFFFF;
    margin:auto;
    position:fixed;
    display: flex;
    justify-content: center;
    /* visibility: hidden;
    opacity: 0; */
    transition: 300ms;
}

.delete-moodboard-popup, .clear-moodboard-popup, .limit-exceed {
    padding: 47px;
    width: 319px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #FFFFFF;
    margin:auto;
    position:fixed;
    text-align: center;
    /* visibility: hidden;
    opacity: 0; */
    transition: 300ms;
}

.limit-exceed .btn-confirm {
    margin: 0 auto;
}

.delete-moodboard-popup p {
    color: grey;
}

.buttons-horizontal-cont button {
    width: 143px;
    height: 46px;
    flex-shrink: 0;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    color:#FFFFFF;
    margin-top: 15px;
    border: none;
    outline: none;
    display: flex;
    align-items:center;
    justify-content: center;
}

.delete-moodboard-popup .buttons-horizontal-cont button:last-child {
    background: transparent;
    border: 1px solid #000;
    color: #000;
}

.delete-moodboard-popup .buttons-horizontal-cont button:first-child {
    background: #EB5757;
}

.buttons-horizontal-cont {
    display: flex;
    justify-content: space-around;
}

.clear-moodboard-popup .buttons-horizontal-cont button:first-child {
    background: #000;
}

.clear-moodboard-popup .buttons-horizontal-cont button:last-child {
    background: transparent;
    border: 1px solid #000;
    color: #000;
}

.btn-sign-up:hover {
    opacity: 80%;
}

.btn-sign-in:hover {
    border: 1px solid #000;
    opacity: 80%;
}

.sign-up-popup {
    padding: 60px 81px;
    width: auto;
    height: auto;
    flex-shrink: 0;
    border-radius: 5px;
    background: #FFFFFF;
    margin:auto;
    position:fixed;
    display: flex;
    justify-content: center;
    /* visibility: hidden;
    opacity: 0; */
    transition: 300ms;
}

.sign-up-popup h2 {
    margin: 0;
}

.sign-up-popup:active {
    visibility: visible;
    opacity: 1;
}

.sign-in-popup {
    padding: 60px 81px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #FFFFFF;
    margin:auto;
    position:fixed;
    display: flex;
    justify-content: center;
    /* visibility: hidden;
    opacity: 0; */
    transition: 300ms;
}  

.sign-in-popup h2 {
    margin: 0;
}

.sign-in-popup:active {
    visibility: visible;
    opacity: 1;
}

.sign-in-popup:target {
    visibility: visible;
    opacity: 1;
}

.share-popup {
    width: 674px;
    padding: 20px 30px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #FFFFFF;
    margin:auto;
    position: fixed;
    margin-bottom: 200px;
    /* visibility: hidden;
    opacity: 0; */
    transition: 300ms;
    color: #828282;
}

.share-popup h2, .grant-access h2 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    align-self: flex-start;
}

.share-popup p {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
}

.share-popup section {
    padding: 12px 0 6px 0;
    width: 100%;
    display: flex;
}

.share-popup section:last-child {
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.share-popup-text-cont {
    width: 78.5%;
    margin-right: 1.5%;
    padding-left: 5px;
    background: rgba(176, 176, 176, 0.2);
    color: #000;
    border: 1px solid gray;
    border-radius: 3px;
    line-height: 28px;
    overflow-wrap: break-word;
}

.share-popup button {
    outline: none;
    cursor: pointer;
    font-size: 16px;
    border: none;
    border-radius: 3px;
    background: var(--main-color-1);
    color: #fff;
    width: 20%;
}

.share-popup-text-cont p {
    font-weight: 400;
    font-size: 14px;
}

.share-popup:active {
    visibility: visible;
    opacity: 1;
}

.forgot-pass-popup {
    padding: 60px 80px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #FFF;
    margin: 0 auto;
    position:fixed;
    display: flex;
    justify-content: center;
    /* visibility: hidden;
    opacity: 0; */
    transition: 300ms;
}

.forgot-pass-popup form {
    width: 100%;
}

.forgot-pass-popup:active {
    visibility: visible;
    opacity: 1;
}

.popup-close {
    width: 100%;
    height: 100%;
    margin: 0;
    overflow: hidden;
    top: 0;
    left: 0;
}

.all {
    position:fixed;
    z-index: 300;
    height: 100%;
    margin: 0;
    overflow: hidden;
    display:flex;
    align-items:center;
    justify-content: center;
    left: 0;
    top: 0;
    background: rgba(0,0,0,.7);
    opacity: 0.5;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: 300ms;
}

.input-box {
    width: 251px;
}

.input-box p {
    color: grey;
    margin: 10px 0 0 0;
}

h2 {
    color: #000;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
}

.search-box-3{
    width:250px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid var(--gray-5, #E0E0E0);
    display: flex;
    align-items: center;
}

.btn-confirm {
    width: 251px;
    height: 46px;
    flex-shrink: 0;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    background: #000;
    color:#FFFFFF;
    margin-top: 15px;
    border: none;
    outline: none;
    display: flex;
    align-items:center;
    justify-content: center;
}

.buttons-vertical-container {
    width: 100%;
    min-height: 108px;
}

.input-2 {
    width: auto;
    height: 38px;
    width: 250px;
    border: none;
    outline: none;
    font-size: 18px;
    opacity: 0.699999988079071;
    background-color: rgba(0, 0, 0, 0);
    position: static;
}

#title {
    max-width: 918px;
    flex-shrink: 0;
    color: var(--gray-2, #040404);
    opacity: 0.7;
    text-align: center;
    font-size: 64px;
    line-height: 60px;
    margin: 0 auto;
    margin-top: 160px;
}

#sub-title {
    width: 550px;
    height: 60px;
    flex-shrink: 0;
    color: var(--grey, #4F4F4F);
    text-align: center;
    font-size: 22px;
    line-height: 60px;
    margin: 0 auto;
    line-height: 1.7em;
    margin-top: 15px;
}

.search-box-container {
    margin: 70px 5.5% 0 5.5%;
    width: 89%;
}

.block-2 {
    width: 100%;
    display: flex;
    flex-flow: nowrap row;
    align-items: baseline;
}

.find-point {
    cursor: pointer;
    font-size: 16px;
    margin: 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: black;
}

.settings-button:hover ~ .settings-dropdown{
    visibility: visible;
    opacity: 1;
}

.settings-dropdown:hover {
    visibility: visible;
    opacity: 1;
}

.settings-dropdown {
    width: 126px;
    height: 80px;
    border-radius: 5px;
    border: 1px solid var(--gray-5, #E0E0E0);
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    opacity: 0;
    position: absolute;
    font-size: 16px;
    font-weight: 500;
    right: 0;
    top: 0;
    visibility: hidden;
    z-index: 1000;
    transition: 300ms;
}

.under-block-2 {
    display: flex;
    width: 100%;
    margin-bottom: 2%;
}

.block-2 h1 {
    color: var(--gray-2, #4F4F4F);
    font-weight: 500;
    margin: 0 10px 0 40px;
}


.block {
    display:flex;
    min-width:fit-content;
    justify-content: flex-start;
    align-items:baseline;
    width: 100%;
}

.search-box {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    border: 1px solid var(--gray-5, #E0E0E0);
    margin-top: 50px;
    display: flex;
    align-items: center;
}

.search-box-2 {
    height: 50px;
    width: 95%;
    border-radius: 5px;
    border: 1px solid var(--gray-5, #E0E0E0);
    margin-top: 50px;
    display: flex;
    align-items: left;
    margin: 0 0 0 0;
    justify-self: flex-start;
}

.text-box-2 {
    text-align: left;
    display: flex;
    width: 47.5%;
    justify-content: left;
    flex-flow: wrap row;
}

.text-box-2 p {
    margin: 0;
    padding: 0 8px 11px 0;
    color:#828282;
    cursor: pointer;
}

.input-1 {
    border: none;
    outline: none;
    width: 97%;
    height: 48px;
    font-size: 18px;
    opacity: 0.699999988079071;
    background-color: rgba(0, 0, 0, 0);
    position:static;
    margin-left: 10px;
}

.search-hints:hover {
    color:rgb(7, 7, 7);
    opacity: 70%;
}

#search-button-2 {
    border: none;
    background-color: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid var(--gray-5, #E0E0E0);
    cursor: pointer;
}

#search-button-1 {
    border: none;
    display: flex;
    justify-content: center;
    background-color: white;
    border-radius: 5px;
    width: 50px;
    cursor: pointer;
}

#search-button-2, #search-button-2:focus, #search-button-2:hover, #search-button-2:active {
    outline: none;
    box-shadow:none;
}

.img-search {
    position: static;
    margin: 13px;
}
.img-search:hover {
    color:#000000;
    opacity: 80%;
}

.text-btn {
    color: var(--gray-3, #828282);
    font-size: 14px;
}

.text-box {
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    max-width: 947px;
    width: 100%;
    justify-content: left;
}

.text-box p {
    margin: 0;
    padding: 11px 16px 0 0;
    color:#828282;
    cursor: pointer;
}

.text-box p:hover {
    opacity: 0.7;
    color:rgb(7, 7, 7);
}

.sliders{
    cursor: pointer;
    display:flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    border-right: 1px solid var(--gray-5, #E0E0E0);
}

.img-sliders{
    width: 26px;
}

/* .sliders:hover {
    border-color:rgb(7, 7, 7);
} */

figure img {
  max-width: 100%;
  display: block;
  width: 500px;
}  

figure div {
    margin: 1px; 
}

figure {
  margin: 1.8em;
  display: flex;
  justify-content: center;
  align-items: auto;
}  

.main-screen {
    display: grid;
    row-gap: 10px;
    padding: 60px 20px 20px 20px;
    column-gap: 5px;
    justify-content: space-between;
    grid-template-columns: repeat(5, 1fr);
}

.main-screen div img {
    width: 100%;
}

.main-screen-bottom {
    position: relative;
    min-height: 800px;
    overflow: hidden;
    z-index: -30;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    -webkit-mask-image: linear-gradient(
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) 45%,
        rgba(0, 0, 0, 0.9) 55%,
        rgba(0, 0, 0, 0.1) 75%,
        rgba(0, 0, 0, 0) 85%
    );
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; 
}

.footer {
    height: 198px;
    margin-top: -198px;
    width: 100%;
    background: #fff;
    /* border-top: 2px solid var(--main-grey-2); */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.footer-section:last-child {
    padding-right: 20px;
}

.footer-section:first-child {
    padding-left: 20px;
}

.footer-section {
    padding: 10px;
}

.footer-section ul {
    margin: 0;
}

.footer-section ul li {
    margin: 0 0 10px 0;
    color: rgba(79, 79, 79, 1);
}

.footer-info-cont {
    display: flex;
    align-items: baseline;
    width: 100%;
}

.footer-info-cont p, h3 {
    margin: 0;
    padding-right: 5px;
    text-align: center;
    color: #6d6d6d
}


.footer h1 {
    margin: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 2.5em;
    width: 100%;
    font-weight: 400;
    color: rgba(79, 79, 79, 1);
}

.side-window {
    position: fixed;
    height: 100%;
    width: 286px;
    background: white;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;
    opacity: 0;
    visibility: hidden;
    transition: 300ms;
}

.side-window:active {
    opacity: 1;
    visibility: visible;
    z-index: 300;
}

.side-window p {
    color: var(--gray-3, #828282);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.filters-manage {
    width: 100%;
    height: 50px;
    right: 0;
    display: flex;
    border-bottom: 1px solid var(--gray-5, #E0E0E0);
}

.side-window:target {
    visibility: visible;
    opacity: 1;
    transition: 300ms;
}

.side-window h2 {
    color: var(--gray-3, #828282);
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right:auto;
    display: flex;
    margin: 20px 0 10px 15px;
}

.side-window .side-window-close {
    width: 50px;
    height: 50px;
    display:flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
    margin-left:auto;
}

.side-window .colors {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    height: 80px;
}

.side-window .search-button {
    width: 230px;
    height: 46px;
    border-radius: 5px;
    border: none;
    color: #fff;
    background: #000;
    font-size: 16px;
    cursor: pointer;
    outline: none;
}

.side-window .other-selectors {
    row-gap: 10px;
    align-items: center;
    width: 200px;
    position: relative;
}

.side-window .checkbox-row {
    display: flex;
    margin: 10px 0 0 0;
    align-items: center;
    justify-content: flex-start;
}

.side-window .checkbox-row:first-child {
    margin: 0;
}

.other-selectors p {
    margin: 0;
}

.side-window .color-selector {
    border-radius: 50%;
    margin: 9px 4px;
    border: 1px solid var(--gray-5, #E0E0E0);
    height: 28px;
    width: 28px;
}

.color-selector-border:first-of-type {
    overflow: hidden;
}

.color-selector-border {
    border: 1px solid var(--gray-5, #E0E0E0);
    height: 28px;
    width: 28px;
    margin: 9px 4px;
    border-radius: 50%;
}

.side-window-radio-colour {
    opacity: 0;
    display: none;
}

.side-window .min-block {
    width: 100%;
    display: flex;
    padding-left: 15px;
}

.colors label .side-window-radio-colour:checked ~ .color-selector{
    border: 2px solid var(--main-color-1);
    height: 26px;
    width: 26px;
}

.colors label .black-selector:checked ~ .color-selector{
    border: 2px solid var(--main-grey);
    height: 26px;
    width: 26px;
}

.colors label .side-window-radio-colour:checked ~ .color-selector-border{
    border: 2px solid var(--main-color-1);
    height: 26px;
    width: 26px;
}

.side-window .source {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    height: 80px;
}

.side-window-checkbox-source {
    opacity: 0;
    display: none;
}

.side-window-checkbox-source:checked ~ .source-selector {
    border: 1px solid gray;
    height: 38px;
    width: 38px;
}


.side-window-checkbox-source:checked~ .highload2 {
    background: var(--main-color-1);
    color: #fff;
}   

.highload2:after {
    content: "";
    position: absolute;
    display: none;
}

.side-window-checkbox-source:checked.highload2:after {
    display: block;
}

.side-window .source-selector {
    height: 40px;
    width: 40px;
}

.source-selector img {
    height: 100%;
    width: 100%;
}

.container {
    width: 24px;
    height: 24px;
    margin: 0 10px 0 0;
    border: 1px solid var(--main-grey);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: var(--grey, #4F4F4F);
}

/* скрываем дефолтный флажок */ .check-highload {
    position: absolute;
    opacity: 0;
}

  /* Создаем кастомный checkbox */.highload2 {
    width: 18px;
    height: 18px;
    color: var(--main-grey);
    text-align: center;
    align-self: center;
    color: var(--grey, #8d8c8c);
    line-height: 24px;
    border-radius: 3px;
    display:flex;
    justify-content: center;
    align-items: center;
}

  /* Когда отмечен, то красим в зеленый цвет */.container .check-highload:checked ~ .highload2 {
    background-color: var(--main-color-1);
    color: #fff;
}   

  /* Создаем когда отмечено (не видно, когда не отмечено) */.highload2:after {
    content: "";
    position: absolute;
    display: none;
}

  /* Показываем когда отмечен */.container .check-highload:checked.highload2:after {
    display: block;
}

.side-window fieldset {
    border: none;
}

.side-window .min-block-2 {
    display: flex;
    margin: 16px;
    align-items: center;
    margin:0;
}

.page-pos {
    margin-left: 3px;
}

.min-block-2 .rect-highload {
    opacity: 0;
    display: none;
}

.min-block-2 .rect-highload:checked ~ .rectangle0{
    border: 2px solid var(--main-color-1);
    Width: 46px;
    Height: 31px;
}

.min-block-2 .rect-highload:checked ~ .rectangle1{
    border: 2px solid var(--main-color-1);
    Width: 31px;
    Height: 46px;
}

.min-block-2 .rect-highload:checked ~ .rectangle2{
    border: 2px solid var(--main-color-1);
    Width: 31px;
    Height: 31px;
}

.rectangle0 {
    Width: 48px;
    Height: 33px;
    border: 1px solid var(--main-grey);
    opacity: .7;
    margin-right: 25px;
}

.rectangle1 {
    Width: 33px;
    Height: 48px;
    border: 1px solid var(--main-grey);
    opacity: .7;
    margin-right: 25px;
}

.rectangle2 {
    Width: 33px;
    Height: 33px;
    border: 1px solid var(--main-grey);
    opacity: .7;
    margin-right: 25px;
} 

.rectangle-cont {
    width: 40px;
    height: 40px;
    display: flex;
    margin-right: 25px;
}

.rectangle-cont0 {
    margin-right: 2px;
    width: 22px;
    height: 40px;
    border: 1px solid var(--main-grey);
    opacity: .7;
}

.rectangle-cont1 {
    width: 18px;
    height: 40px;
}

.rectangle-cont1-1 {
    width: 16px;
    height: 18px;
    border: 1px solid var(--main-grey);
    opacity: .7;
}

.rectangle-cont1-1:first-child {
    margin-bottom: 2px;
}

.min-block-2 .rect-highload:checked ~ .rectangle-cont .rectangle-cont0{
    border: 2px solid var(--main-color-1);
    width: 20px;
    height: 38px;
}

.min-block-2 .rect-highload:checked ~ .rectangle-cont .rectangle-cont1-1{
    border: 2px solid var(--main-color-1);
    width: 14px;
    height: 16px;
}


.Board {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 3fr 1fr 3fr;
    width: 100%;
    position: relative;
    margin: 60px 0 0 0;
    padding: 0 0 7px 0;
    /* box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25); */
    border-bottom: 0.5px solid var(--main-grey-2);

}

.change-scale-section {
    display: grid;
    background: #FFF;
    grid-template-columns: 1fr 2fr 1fr;
    height: 35px;
    color: rgba(79, 79, 79, 1);
}

.change-scale-section div:first-child {
    width: 50px;
}

.plus-minus-buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 70%;
}

.plus-minus-buttons::after {
    content: '';
    width: 1px;
    height: 19px;
    background: var(--main-grey-2);
}

.plus-minus-buttons::before {
    content: '';
    width: 1px;
    height: 19px;
    background: var(--main-grey-2);
}

.plus-minus-buttons p {
    width: 40px;
    font-size: 15px;
    text-align: center;
    color: #000;
    padding: 0 5px;
    line-height: 22px;
    background: rgba(161, 157, 157, 0.4);
}

.auto-scale img {
    height: 26px;
    margin: 0 6px;
}

.auto-scale {
    height: 36px;
    width: 50px;
    justify-content: flex-end;
}

.change-scale-section button {
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; 
}

.change-scale-section .plus-minus-buttons button img {
    height: 14px;
    margin: 0 6px;
}

.MB-preview-name {
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 30px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgba(79, 79, 79, 1);
    margin: 0 0 0 15px;
    font-weight: 400;
}


.MB-tag-preview-not-found {
    border: none;
    font-size: 30px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgba(79, 79, 79, 1);
    font-weight: 400;
}

.MB-preview-not-found {
    height: auto;
    justify-content: center;
    margin: 0;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #E0E0E0;
    background: #FAFAFA;
    color: var(--main-grey);
    align-items: center;
    display: flex;
}

.Board input {
    font-size: 30px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgba(79, 79, 79, 1);
    font-weight: 400;
}

.panel-board {
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: right;
    margin-right: 20px;
    margin-left: auto;
}

.find-panel-board {
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: right;
    margin-right: 20px;
}

.find-panel-board .find-point:first-child {
    margin: 0 12px 0 0;
}

.panel-board div:first-child {
    border-left: none;
}

.panel-board div:first-child img {
    margin: 0;
}

.MB-name {
    border: none;
    font-size: 30px;
    width: 100%;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgba(79, 79, 79, 1);
}

.MB-name-holder {
    width: 47.5%;
    border: none;
}

.MB-name-holder:focus {
    outline: none;
}


.MB-name:focus {
    outline: none;
}

.MB-name:hover {
    background: rgba(79, 79, 79, 0.1);
}

.point {
    cursor: pointer;
    font-size: 16px;
    margin: 0 0 0 12px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: black;
    border-left: 1px solid var(--main-grey-2);
}

.point img {
    margin: 0 12px;
}

.point-0{
    height: 32px;
    font-size: 16px;
    line-height: 32px;
    margin-right: 10px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: black;
}

.point-01 {
    height: 32px;
    font-size: 16px;
    line-height: 32px;
    margin-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.show-style {
    display: flex;
    justify-self: end;
}

.show-style-sliders-1, .show-style-sliders-2 {
    display:flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid var(--gray-5, #E0E0E0);
    margin-top: auto;
}

.sa {
    border: 2px solid var(--main-color-1);
    width: 48px;
    height: 48px;
}

.arrow-box {
    display:flex;
    justify-content: center;
    width: 18px;
    height: 18px;
    margin: 0 25px;
}

#Untitled_board {
    color: #A5A5A5;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    text-align: center;
}

#Untitled_board:hover {
    color:rgb(7, 7, 7);
    opacity: 70%;
}

#enter-field {
    height: 323px;
    flex-shrink: 0;
    justify-content:center;
    margin: 0;
    width: 100%;
    border-radius: 5px;
    border: 1px solid var(--gray-5, #E0E0E0);
    background: #FAFAFA;
    color: var(--main-grey);
    display: none;
    align-items: center;
}

.select-block {
    display: grid;
    width: 100%;
    height: 100%;
}

.select-block-scroll-section {
    width: 95%; 
    overflow: scroll;
    height: 100%;
}

.select-block-empty {
    margin-top: 35px;
    height: 282px;
    width: 41.6%;
    padding: 0.45%;
    flex-shrink: 0;
    justify-content:center;
    border-radius: 5px;
    border: 1px solid var(--gray-5, #E0E0E0);
    background: #FAFAFA;
    color: var(--main-grey);
    display: flex;
    align-items: center;
    position: absolute;
}

.select-block-empty h1 {
    font-weight: 400;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.select-block-empty img {
    width: 10%;
    margin: 0 3% 0 0;
    fill: var(--main-grey)
}

#render-more {
    width: 100%;
    height: 100px;
    text-align: center;
}

#render-more img {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
}

#render-more p {
    margin-bottom: 2px;
}

#enter-field-images {
    flex-shrink: 0;
    justify-content:center;
    margin: 0 20px 0 0;
    border-radius: 5px;
    column-gap: 2px;
    width: 50%;
}


.selected-images {
    width: 100%;
    height: 282px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #FAFAFA;
    color: var(--main-grey);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 100px;
    position: sticky;
}

.selected-images p {
    margin-bottom: 20px;
    text-align: center;
    line-height: 26px;
}

.small-white-icon {
    background-color: white;
    height: 24px;
    width: 24px;
    position: relative;
    border-radius: 50px;
    display: none;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.result-image {
    width: 100%;
    height: auto;
    position: relative;
    margin: 0;
    opacity: 1;
    transition: opacity 200ms;  
}

.result-cont {
    opacity: 0;
    visibility: hidden;
    position: relative;
    display: inline-block;
}

.result-cont.active {
    transition: 1000ms;
    opacity: 1;
    visibility: visible;
}

.result-image:hover {
    opacity: 0.6;
}

.result-cont:hover .result-image {
    opacity: 0.6;
}

.result-cont:hover .small-white-icon {
    display: flex;
    margin-top: -26px;
    margin-left: 2px;
}

.grid-result-column {
    width: 33%;
}

.grid-result-gutter {
    width: 0.55%;
}

#result-grid {
    width: 100%;
    height: 72vh;
    overflow: scroll;
}

/* .new-moodboard .result-image {
    margin-top: 5px;
    margin-bottom: 5px;
    align-self: center;
    object-fit: cover;
    height: 100%;
    width: 100% !important;
    };

.new-moodboard {
    display: grid;
    grid-template-columns: 50px 20px 40px 70px;
    grid-gap: 2px;
    grid-auto-rows: min-max(80px, auto);
    grid-auto-flow: dense;
}; */

/* ---- grid ---- */

.grid-section {
    display: flex;
    width: 100%;
    height: 77vh;
    margin: 0;
    border-radius: 5px;
}

.grid-scroll-section {
    background: #FAFAFA;
    border: 1px solid var(--Gray-5, #E0E0E0);
    border-radius: 5px;
    width: 98%; 
    padding: 1%;
    margin: 0;
    max-height: 66.8vh; 
    overflow: scroll;
}
  
  /* ---- .grid-item ---- */ 

.grid-item:hover {
    border-color: hsla(0, 0%, 100%, 0.5);
    cursor: move;
}
  
.grid-item-cont.is-dragging,
.grid-item-cont.is-positioning-post-drag {
    z-index: 2;
    width: 24.62%;
    margin: 0;
}

.packery-drop-placeholder {
    outline: 3px dashed hsla(0, 0%, 0%, 0.5);
    background-clip: padding-box;
    outline-offset: -6px;
    -webkit-transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
}

.grid-item:hover ~ .grid-item-cont {
    opacity: 0.6
}

.grid-item-cont {
    position: relative;
    height: auto;
    display: inline-block;
}

.grid-item-cont .comment-icon {
    background: #fff;
    opacity: 1;
    border: 0.5px solid #fff;
    margin: 12px;
}

.grid-item:hover {
    opacity: 0.6;
}

.grid-item-cont:hover .grid-item {
    opacity: 0.6;
}

.grid-item-cont:hover .small-white-icon {
    display: flex;
    margin-top: -26px;
    margin-left: 2px;
}

.grid-item {
    z-index: 0;
    transition: opacity 200ms;  
}

.main-block {
    height: 100%;
    width: 100%;
    display: flex;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; 
}

#main-grid-cont {
    display: flex; 
    margin: 0 5.5%;
    height: calc(100% - 220px)
}

.select-image-block {
    width: 100%;
}

.grid-column, .grid-item-cont {
    width: 24.62%;
}

.grid-gutter {
    width: 0.5%;
}

.grid-item-cont {
    display: grid;
    z-index: 0;
    position: relative;
    background-color: black;
    height: auto;
    margin: 0;
}

.grid-item {
    position: relative;
    width: 100%;
    object-fit: cover;
}

.small-minus-icon {
    transition: 300ms;
    background-color: white;
    height: 24px;
    width: 24px;
    z-index: 30;
    position: absolute;
    border-radius: 50px;
    bottom: 5px;
    left: 5px;
    display: flex;
    visibility: hidden;
    opacity: 0;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.grid-item-cont:hover .small-minus-icon {
    visibility: visible;
    opacity: 1;
}

.right-section h2{
    top: 60px;
    color: var(--Gray-2, #4F4F4F);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    text-align: left;
    margin: 0;
    margin-bottom: 12px;
    margin-top: -5px;
}

.right-section {
    height: 100%;
}

/*--------------------------*/
       /*Boards Page*/
/*--------------------------*/


.Boards-cont {
    display: flex;
    margin: 80px 5%;
    height: max-content;
    justify-content: center;
}

.Boards-list {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(4, 25%);
}

.rect-cont p {
    margin: 0 auto;
    margin: 0px auto;
    width: 85%;
    max-width: 281px;
    color: #828282;
    font-weight: 500;
    margin-top: 2%;
    font-size: 18px;
    
}

.rect-cont {
    width: 100%;
    margin-bottom: 20px;
}

.rect {
    margin: 0 auto;
    max-width: 400px;
    width: 85%;
    height: 209px;
    flex-shrink: 0;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid var(--gray-3, #e6e4e4);
    background: rgba(176, 176, 176, 0.2);
    position: relative;
    z-index: 0;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; 
}

.rect-cont form {
    margin: 0 auto;
    width: 85%; 
    max-width: 400px;
    color: #828282;
}

.rect-cont p {
    width: 85%; 
    max-width: 400px;
    color: #828282;
}

.plus {
    width: 64px;
    height: 64px;
    background-color: white;
    border-radius: 50%;
    display:flex;
    justify-content: center;
    align-items:center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.line-1 {
    height: 30px;
    width: 3px;
    background-color: rgb(103, 103, 103);
    border-radius: 50px;
}

.line-2 {
    height: 3px;
    width: 30px;
    background-color: rgb(103, 103, 103);
    position: absolute;
    border-radius: 50px;
}

.more-vertical {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-top: 12px;
    margin-left: auto;
}

.more-vertical:hover ~ .dropdown {
    visibility: visible;
    opacity: 1;
}

.dropdown:hover {
    visibility: visible;
    opacity: 1;
}

.more-vertical div {
    width: 4px;
    height: 4px;
    background-color:#a4a3a3;
    border-radius: 50%;
    margin: 3px 0 0 10px;
}

.grid {
    z-index: -1;
    margin: auto;
    width: 85%;
    height: 100%;
}

.grid-width, .grid-item-boards {
    width: 24.5%;
    display: flex;
    z-index: -2;
}

.grid-item-boards img {
    width: 100%;
    height: 100%;
}

.grid-item-boards .comment-icon {
    height: 10px;
    width: 10px;
    margin: 2px;
    opacity: 1;
    border: 0.6px solid #FFF;
}

.grid-bottom {
    z-index: -30;
    height: 85%;
    margin-right: auto;
    margin-left: auto;
    -webkit-mask-image: linear-gradient(
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.8) 30%,
        rgba(0, 0, 0, 0.5) 45%,
        rgba(0, 0, 0, 0.1) 60%,
        rgba(0, 0, 0, 0) 80%);
}

.boards-grid-gutter {
    width: 0.7%;
}

.dropdown {
    height: 198px;
    border-radius: 5px;
    border: 1px solid var(--gray-5, #E0E0E0);
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
    display: grid;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    top: 1%;
    right: 2%;
    transition: 300ms;
}

.dropdown div {
    display: flex;
    z-index: 100;
    margin: 0 18px;
    cursor: pointer;
}

.dropdown div p {
    margin: 0;
}

.dropdown div img {
    margin: 0 14px 0 0;
    height: 20px;
}

.dropdown div p:hover {
    color:rgb(7, 7, 7);
    opacity: 70%;
}

.moodBoard-boards-name {
    border: none;
    padding: 0;
    color: #828282;
    font-weight: 500;
    margin-top: 2%;
    font-size: 18px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    /* margin-left: 3.5%; */
    width: 100%;
    background: none;
    -webkit-text-fill-color: #828282;
    opacity: 1;
}


.moodBoard-boards-name:focus {
    outline: none;
}

.moodBoard-boards-name::placeholder {
    -webkit-text-fill-color: var(--main-grey-2);
    color: var(--main-grey-2);
    
}

/*--------------------------*/
       /*Preview Page*/
/*--------------------------*/

.escape {
    margin: 0 12px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.grid-container {
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 10px;
}

.preview-grid {
    width: 100%;
    margin: 0;
    height: auto;
    /* background: #FAFAFA;
    border: 1px solid var(--Gray-5, #E0E0E0);
    border-radius: 5px; */
}

.preview-grid-column, .switch-cont {
    width: 24.62%;
    margin: 0;
}

.switch-cont {
    display: grid;
    height: auto;
}

.switch-photo {
    width: 100%;
}

.bigger-icon {
    z-index: 100;
    height: 30px; 
    width: 30px;
    margin: 5px;
    right: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: 200ms;
}

.bigger-icon .active {
    opacity: 1;
    visibility: visible;
}

.preview-grid-gutter {
    width: 0.5%;
    margin: 0;
}

.preview-grid input {
    visibility: hidden;
    opacity: 0;
    position: absolute;
}

.img-preview {
    position:fixed;
    z-index: 200;
    display:flex;
    align-items:center;
    justify-content: center;
    left: 0;
    top: 0;
    background: rgb(47, 47, 47);
    opacity: 0.5;
    width: 100%;
    height: 100%;
    overflow: cover;
    opacity: 0;
    visibility: hidden;
    transition: all 300ms;
    /* -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;  */
}

.active {
    opacity: 1;
    visibility: visible;
}

.close-preview {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
}

.preview-image {
    height: 100%;
    width: 100%;
    display: block;
    object-fit: contain;
}

.preview-image-cont {
    margin: 0 auto;
    display: flex;
    opacity: 1;
    background:#525252;
    height: 100%;
    width: 100%;
}

.preview-image-holder {
    height: 100%;
    width: 100%;
    z-index: 700;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.alt-text-holder {
    position: fixed;
    width: 60%;
    top: 0;
    display: flex;
    align-items: flex-end;
    overflow-wrap: break-word;
    z-index: 20;
    color: #CBCBCB;
}

.alt-text-holder p {
    margin: 3px 0 0 0;
}

.preview-image-cont .alt-text-holder:last-child {
    top: 86%;
}

.preview-image-cont div img {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; 
}

.change-image-zone {
    background: rgba(176, 176, 176, 0.2);
    height: 70%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-rows: 100%;
    color: white;
}

.change-img-left {
    cursor: pointer;
    align-items:center;
    justify-content: center;
    border-radius: 0 3px 3px 0;
    left: 0;
    display: flex;
}  

.change-img-left img {
    width: 30px;
    height: 30px;
    margin: 0 auto;
     -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; 
}

.change-img-right {
    cursor: pointer;
    right: 0;
    display: flex;
    align-items:center;
    justify-content: center;
    border-radius: 3px 0 0 3px;
}

.change-img-right img {
    width: 30px;
    height: 30px;
    margin: 0 auto;
     -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; 
}

.find-similar {
    width: 148px;
    height: 34px;
    background: #000;
    border-radius: 50px;
    display: flex;
    margin-top: -2.5rem;
    margin-left: 0.3rem;
    z-index: 300;
    opacity: 1;
    vertical-align: middle;
}

.find-similar p{
    height: 2px;
    line-height: 2px;
    margin-left: 6px;
}

.white-icon {
    background: white;
    height: 34px;
    width: 34px;
    position: relative;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #fff;
}

.preview-loader {
    z-index: 50;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #fff;
}

.result-loader {
    z-index: 100;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background: #fff;
}

.dropzone {
    background: #000;
    margin-top: -3px;
    width: 113px;
    outline: 1px solid black;
    height: 26px;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    align-items: center;
    color: #FFF;
    cursor: pointer;
}

@-webkit-keyframes autosave {
    0% {top: 0px; right:-260px;}
    30% {top: 0px; right:0px;}
    40% {top: 0px; right:0px;}
    50% {top: 0px; right:0px;}
    60% {top: 0px; right:0px;}
    70% {top: 0px; right:0px;}
    100% {top: 0px; right:-260px;}
}

@keyframes autosave {
    0% {top: 0px; right:-260px;}
    20% {top: 0px; right:0px;}
    40% {top: 0px; right:0px;}
    50% {top: 0px; right:0px;}
    60% {top: 0px; right:0px;}
    80% {top: 0px; right:0px;}
    100% {top: 0px; right:-260px;}
}

.auto-save-popup {
    width: 240px;
    padding: 2.5px 10px;
    height: 45px;
    position: absolute;
    top: 0px;
    right:-260px;
    background: #FFF;
    align-items: center;
    background: var(--main-grey-2);
    justify-content: space-around;
    display: flex;
    opacity: 1;
    z-index: 500;
}

.auto-save-popup.active {
    -webkit-animation-name: autosave;
    animation-name: autosave;
    -webkit-animation-duration: 5s;
    animation-duration: 5s;
}

.auto-save-popup h2 {
    height: auto;
    color: #27AE60;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}

.auto-save-popup p {
    margin: 0;
    color: #828282;
}

.auto-save-popup p:first-child {
    font-weight: 700;
}

.auto-save-popup p:last-child {
    font-weight: 100;
}

.email-verification {
    width: 30%;
    height: 30%;
    position: absolute;
    top: 10%;
    left: 35%;
    text-align: center;
}

.email-verification h1 {
    flex-shrink: 0;
    color: var(--gray-2, #040404);
    opacity: 0.7;
    font-weight: 400;
    font-size: 40px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.email-verification p {
    color: #828282;
    font-size: 20px;
}

.email-verification img {
    margin: 0 auto;
    width: 200px;
}

.rename-board {
    background: #FFF;
    padding: 47.5px;
    border-radius: 5px;
    margin: 0 auto;
    transition: 300ms;
    position: absolute;
} 

.rename-board h1 {
    margin: 0 0 20px 0;
    font-weight: 350;
}

.rename-board p {
    margin: 0;
    color: var(--main-grey-3)
}

.rename-board input, .grant-access input {
    width: 466px;
    color: rgba(79, 79, 79, 1);
    border: 1px solid #E0E0E0;
    outline: none;
    border-radius: 5px;
    height: 40px;
    padding-left: 10px;
    font-size: 20px;
}

.rename-board .buttons-horizontal-cont button:first-child {
    background: #000;
}


.rename-board .buttons-horizontal-cont button:last-child {
    background: transparent;
    border: 1px solid #000;
    color: #000;
}

.context-menu {
    height: 0;
    width: 198px;
    border-radius: 5px;
    border: 1px solid var(--gray-5, #E0E0E0);
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
    overflow: hidden;
    top: 1%;
    right: 2%;
    z-index: 999;
    color: black;
    opacity: 0;
    transition: opacity 300ms ease-out;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; 
}

.context-menu.active {
    opacity: 1;
    height: auto;
    transition: 300ms;
}

.context-menu p {
    cursor: pointer;
    margin: 10px;
    font-size: 17px;
    height: auto;
    font-weight: 500;
}

.context-menu p:hover {
    color:rgb(7, 7, 7);
    opacity: 70%;
}

.context-menu-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
}

.context-menu-content {
    width: 100%;
    height: 100%;
    opacity: 0;
    display: none;
    transition: opacity 300ms;
}

.context-menu-content.active {
    transition: 300ms;
    opacity: 1;
    display: block
}

.context-menu-input textarea {
    border: none;
    outline: none;
    width: 97%;
    box-sizing: border-box;
    height: 90%;
    margin: 2px;
    padding: 6px 0px;
    resize: none;
    line-height: 1.5;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

.context-menu-input {
    height: auto;
    width: auto;
    margin: 2px;
}

.context-menu-button-cont button:nth-child(1) {
    margin: 0px 3px 0px 0px;
    border-radius: 5px;
    border: none;
    height: 25px;
    color: #FFF;
    background: #000000;
}

.context-menu-button-cont button:nth-child(2) {
    margin: 0px 3px 0px 0px;
    border-radius: 5px;
    border: 1px solid #000;
    height: 25px;
    color: #000;
    background: #FFF;
}

.context-menu-button-cont {
    display: flex;
    align-items: center;
}

.context-menu-button-cont p {
    font-size: 13px;
    color:rgb(7, 7, 7);
    opacity: 70%;
    cursor: default;
    margin: 0 0 0 30px;
}

.comment-container {
    position: absolute;
    z-index: 30;
}

.comment-icon {
    position: absolute;
    border: 2px solid #000;
    height: 28px;
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background: #FFF;
    z-index: 0;
}

.comment-icon.active {
    opacity: 1;
}

.comment-field {
    max-width: 200px;
    min-width: 130px;
    margin: 20px 0 0 20px;
    opacity: 0;
    height: 0;
    overflow: hidden;
    user-select: none;
    z-index: 0;
    transition: opacity 300ms ease-out;
}

.comment-field p {
    margin: 4px 0px;
}

.comment-field.active {
    z-index: 100;
    height: auto;
    color: var(--main-grey-3);
    opacity: 1;
    padding: 5px 7px;
    border-radius: 3px;
    background: #FFF;
}

.comment-body {
    border-radius: 5px;
    width: fit-content;
    max-width: 140px;
    padding: 2px 4px;
    color: #FFF;
    height: auto;
    position: relative;
}

.comment-user {
    background: #000;
    margin: 0 0 3px auto;
}

.comment-non-user {
    background: var(--main-grey-3);
    margin: 0 auto 3px 0;
}

.comment-body p {
    word-wrap: break-word;
    flex-wrap: wrap;
    overflow-wrap: break-word;
    margin-right: 0;
    margin-left: auto;
    position: relative;
}

.comment-menu {
    width: 60px;
    height: auto;
    padding: 2px;
    border-radius: 5px;
    border: 1px solid var(--gray-5, #E0E0E0);
    background: #FFF;
    position: absolute;
    z-index: 10;
    opacity: 0;
    text-align: center;
    visibility: hidden;
    right: 0px;
    transition: 300ms;
    color: var(--main-grey-3);
}

.comment-menu.active {
    visibility: visible;
    opacity: 1;
}

.comment-menu p {
    margin: 0 auto;
}

.comments-scroll-list {
    overflow: scroll;
    width: 100%;
    margin: 0;
    padding: 0;
    max-height: 200px;
    height: auto;
}

.grant-access {
    flex-shrink: 0;
    border-radius: 5px;
    background: #FFFFFF;
    margin:auto;
    z-index: 500;
    transition: 300ms;
    color: #828282;
}

.grant-access section {
    display: flex;
    align-items: center;
    width: 100%;
}

.grant-access .btn-confirm {
    margin: 0 20px 0 0;
    width: 120px;
}

.grant-access ul {
    margin: 0;
    padding: 0;
    width: 100%;
    list-style-type: none;
}

.grant-access ul li {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.grant-access ul li div {
    height: fit-content;
    cursor: pointer;
}

.grant-access ul li div img {
    height: 20px;
}

.grant-access ul li p {
    font-size: 16px;
    font-weight: 500;
}

.grant-access input {
    width: 100%;
}