@import url("/src/colors.css");

.subscription-popup {
    padding: 60px 81px;
    width: auto;
    height: auto;
    flex-shrink: 0;
    border-radius: 5px;
    z-index: 500;
    background: #FFFFFF;
    margin:auto;
    position:fixed;
    display: flex;
    justify-content: center;
    /* visibility: hidden;
    opacity: 0; */
    transition: 300ms;
}

.subscription-plan {
    width: 220px;
    height: 380px;
    border: 1px solid var(--main-grey);
    border-radius: 5px;
}

.subscription-plan h2 {
    font-size: 26px;
    text-align: center;
}

.subscription-plan button {
    border: none;
    height: 40px;
    width: 150px;
    color: #fff;
    border-radius: 5px;
    background-color: #000;
}

.subscription-popup .subscription-plan:first-child {
    margin-right: 10px;
}

.subscription-popup .subscription-plan:nth-child(2) {
    margin-left: 10px;
}